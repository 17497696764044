@import "../../../../../styles/sass/NewThemeVariables.scss";

.model-item {
  display: flex;
  margin: 5px 0px 0px 12px;
  height: $filterItemHeight;
  font-size: $primaryFontSizeXS;

  label {
    margin-bottom: 5px;
    color: $primaryColor;
    font-weight: 300;
    text-transform: capitalize;
  }

  .label-style {
    white-space: nowrap;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  select,
  input[type="number"],
  input[type="text"] {
    border-radius: 3px;
    border: 1px solid $inputEleBorderColor;
    height: $inputEleHeight;
    width: 80px;
    color: $primaryColor;
    
  }
}
